import React from 'react';
import {navigate} from '@reach/router';
import {ModalBody, Button} from 'reactstrap';

import Modal from 'components/Modal';
import OptInForm from 'components/OptInForm';
import ModalContext, {useModal} from 'contexts/modal';
import classNames from 'classnames';
import ShowIf from 'funnel-schedule/ShowIf';

export default function OptinModal({entry, ...props}) {
  const modalProps = useModal();
  const [navigateOnClose, setNavigateOnClose] = React.useState(false);
  const onComplete = React.useCallback(() => {
    modalProps.toggle();
    navigate('/masterclass/watch', {state: {fromEntry: entry}});
  }, []);
  const onStepComplete = React.useCallback((step) => step === 1 && setNavigateOnClose(true), []);
  const toggle = React.useCallback(() => {
    if (navigateOnClose) navigate('/masterclass/watch', {state: {fromEntry: entry}});
    modalProps.toggle();
  }, [navigateOnClose]);
  return (
    <Modal {...props} isOpen={modalProps.isOpen} toggle={toggle} size="lg">
      <ModalBody className="px-3 px-lg-4 pt-0 pb-4">
        <OptInForm onComplete={onComplete} onStepComplete={onStepComplete} id="modalOptInForm" entry={entry} />
      </ModalBody>
    </Modal>
  );
}

export function OptinModalTrigger({
  color = 'cta',
  btnText = 'Reserve My Spot',
  className = undefined,
  classOverride = false,
  ...props
}) {
  const modalProps = useModal();
  return (
    <Button
      color={color}
      size="lg"
      {...props}
      className={classNames(!classOverride && `optin-button-trigger`, className)}
      onClick={modalProps.toggle}
    >
      {btnText}
    </Button>
  );
}
