import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import classNames from 'classnames';
import './FrnModal.scss';

export default function FrnModal({
  isOpen,
  toggle,
  children,
  returnFocusAfterClose = false,
  className = undefined,
  modalBodyClass = undefined,
  size = 'lg'
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      returnFocusAfterClose={returnFocusAfterClose}
      className={classNames(`FrnModal`, className)}
      size={size}
    >
      <ModalHeader
        toggle={toggle}
        className={classNames(`FrnModalHeader`)}
        contentClassName={classNames(`FrnModalContent`)}
      />
      <ModalBody className={classNames(`FrnModalBody`, modalBodyClass)}>{children}</ModalBody>
    </Modal>
  );
}
