import React, {useCallback, useEffect, useState} from 'react';
import {Location} from '@reach/router';
import queryString from 'query-string';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {useUserState} from 'contexts/user';
import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Header from 'components/Header';
import {SectionTriangle, SectionTriangleSpeakerCollapse} from 'components/Elements/Section';
import OptinModal, {OptinModalTrigger} from 'components/OptinModal';
import MasterclassInfographicForm from 'components/MasterclassInfographicForm';
import CountdownTimer from 'components/CountdownTimer';
import {ListIcon} from 'components/Elements/IconList';
import {TestimonialBoxNew, TestimonialBubble} from 'components/Testimonial';
import Footer from 'components/Footer';
import SectionCL from 'components/ComponentLibrary/SectionCL';
import IconListCL from 'components/ComponentLibrary/IconListCL/IconListCL';
import ExitModal from 'components/ComponentLibrary/ExitModal';

export default function (props) {
  return (
    <Location>
      {({location, navigate}) => <MasterClassIndex location={location} navigate={navigate} {...props} />}
    </Location>
  );
}
function MasterClassIndex({
  location = {},
  noShare = false,
  noFixedVideo = true,
  noExitIntent = false,
  linkLogo = false,
  slug = 'masterclass',
  navigate,
  variant = 'default',
  fromEntry = 'Masterclass',
  pageTitle = 'Join the Food For Health Masterclass with John Robbins',
  pageDescription = 'Unlock your unique body’s ultimate potential through this Masterclass with John Robbins where he talks about how you can make food the foundation of YOUR health with these 10 mighty plant-powered breakthroughs. This Masterclass is only available for a limited time, so register today!',
  shareUrl = 'https://thriving.foodrevolution.org/masterclass/',
  shareImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  facebookDescription = 'John Robbins is one of the world’s most beloved natural health experts and inspired millions of people to claim the power of their food choices to take a stand for a healthy life. Right now, you have a very special limited-time opportunity to join John for a totally free Masterclass on how you can make food the foundation of YOUR health. Click here to join him...',
  twitterDescription = 'Find out about 10 mighty #plantpowered breakthroughs that could save millions of lives in this free #masterclass with world-renowned author, John Robbins. Join the FREE #foodforhealthmasterclass here:',
  twitterHashtags = [null],
  twitterImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  pinterestDescription = 'Looking for a sign that it’s time to take charge of your healthy-eating habits? This is it. Join this free masterclass and get the inspiration you need to make your healthy habits stick. Watch here now: https://thriving.foodrevolution.org/masterclass/',
  pinterestImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  ...props
}) {
  const exitModalEnabled = !noExitIntent || variant != 'ad';
  const {email} = useUserState();
  const qs = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {};
  // @ts-ignore
  const {fromInfographic} = qs.fromInfographic ? qs : location.state || {};
  const navigateWatch = useCallback(() => navigate('/masterclass/watch/'), []);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const pathName = window.location.pathname;
      const pathRegex = new RegExp(`\/${slug}\/`, 'g');
      if (!pathName.match(pathRegex)) {
        // @ts-ignore
        navigate(`/${slug}/` + location.search);
      }
    }
  }, []);

  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  function Logo({className = null, src = 'https://cdn.foodrevolution.org/ppt/ppt-masterclass-logo-purpleblack.svg'}) {
    return <img src={src} alt="food for health masterclass logo" style={{maxHeight: '90px'}} className={className} />;
  }

  const optinButtonText = 'Reserve My Spot';
  const ctaButton =
    fromInfographic && email ? (
      <MasterclassInfographicForm fromInfographic={fromInfographic} />
    ) : (
      <OptinModalTrigger btnText={fromEntry === 'plants' ? <>Yes! Sign Me Up!</> : optinButtonText} />
    );

  const launchEnd = new Date(defaultSchedule.launchEnd).getTime();
  function LandingTimer({className = undefined, timerClass = undefined, style = 'dark', ...props}) {
    return (
      <>
        {defaultSchedule.phase === 'during' && (
          <div className={classNames(`section-countdown countdown-${style}`, className)}>
            <h5 className="timer-heading mt-0 mb-2">{defaultSchedule.launchTitle} ends in:</h5>
            <CountdownTimer time={launchEnd} className={timerClass} />
          </div>
        )}
      </>
    );
  }

  useBalanceText();
  return (
    <div className={classNames(`page d-flex flex-column page-nosocial`)}>
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={shareImg} />

      <div className="page-content">
        {fromInfographic && (
          <section className="section-infographic-info background-green text-white py-4">
            <Container>
              <Row className="d-flex align-items-center">
                <Col xs="3" md="2" className="text-center">
                  <img
                    className="img-infographic img-fluid"
                    src="https://cdn.foodrevolution.org/ppt/frn-infographic-foodslongevity.png"
                  />
                </Col>
                <Col xs="9" md="10">
                  <p>
                    Thanks for taking an interest in food and your health! Your infographic of{' '}
                    <i>10 Foods to Eat and 10 Foods to Avoid for Health and Longevity</i> should be in your email inbox
                    in a few minutes. <b>Now take the next step. Learn the secrets to plant-powered thriving.</b>
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        )}

        <Header
          className="header-wrap"
          bgColor="white"
          textColor="black"
          shareUrl={shareUrl}
          shareImg={shareImg}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImg={pinterestImg}
          adLockup={variant === 'ad' ? true : false}
        />

        <SectionCL
          id="pptv1-header-image"
          color="gray"
          imgUrl="https://cdn.foodrevolution.org/ppt/images/1287069719-lg.jpg"
          innerWrapClass="flex-grow-1"
          type="triangle"
          order="first"
          angle="none"
        />
        <SectionCL id="pptv1-header-optin" color="white" type="triangle" angle="both">
          <Container>
            <Row>
              <Col>
                <div className="optin-box background-white p-4 p-lg-5 rounded box-shadow-black-50">
                  <p className="text-h3 text-center text-lg-left text-400">
                    In This <b>80-Minute Masterclass,</b> <br className="d-xl-block" />
                    Get Ready For:
                  </p>
                  <Row className="mx-0">
                    <Col lg="7" xl="8">
                      <div className="d-flex justify-content-center justify-content-lg-start">
                        <IconListCL iconColor="black">
                          <>The big food lies</>
                          <>10 mighty plant-powered breakthroughs</>
                          <>
                            How what we eat, and what we don’t eat, <br className="d-xl-block" />
                            can help save the planet
                          </>
                          <>John’s inspiring story</>
                          <>Why taking control of health matters</>
                          <>Receive a special invitation</>
                        </IconListCL>
                      </div>
                      <div className="text-center text-lg-left">{ctaButton}</div>
                    </Col>
                    <Col lg="5" xl="4" className="d-none d-lg-flex">
                      <div className="optin-box-image">
                        <img src="https://cdn.foodrevolution.org/ppt/images/john-isolated.png" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL id="pptv1-testimonials" color="light-gray" type="triangle">
          <Container>
            <Row>
              <Col>
                <TestimonialBoxNew className="mb-4" bgColor="white" id="woody-harrelson-v2" />
                <TestimonialBoxNew className="mb-4" bgColor="white" id="paul-hawken-v2" />
                <TestimonialBoxNew bgColor="white" id="kris-carr-v2" />
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL id="pptv1-video" color="white" type="triangle">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <img
                  className="w-100"
                  src="https://cdn.foodrevolution.org/ppt/video-thumb/ppt-thumb-240423-v1-noclick.gif"
                  alt="animated graphic with John that says health is your greatest wealth"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center mt-5">{ctaButton}</div>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="" color="gray" imgUrl="https://cdn.foodrevolution.org/ppt/images/1284690585-lg.jpg" angle="none">
          <Container>
            <Row>
              <Col>
                <div className="background-white box-shadow-black p-4 p-lg-5 rounded">
                  <h3 className="section-heading">Hi, I’m John Robbins</h3>
                  <p>
                    When I was growing up, I was raised to be next in line to run the family business. You may have
                    heard of it: Baskin-Robbins (yes, the ice cream company).
                  </p>
                  <p>
                    I also had major health problems as a kid. At the age of five, I had polio. I also had asthma and
                    allergies, and I was constantly getting sick.
                  </p>
                  <p>Then, as a young man, I walked away from the family business and the family fortune. 🍦</p>
                  <p>You see, I’d learned about the dark side of the modern dairy, meat, and sugar industries.</p>
                  <p>
                    I started eating more vegetables, fruits, and whole foods — and I even threw out the ice cream that
                    was in my freezer.
                  </p>
                  <p>And one by one, my health problems vanished. ✨</p>
                  <p>Since then, I’ve made it my life’s mission to reveal the truth about food and health.</p>
                  <p>I’ve teamed up with my son Ocean to bring this message to as many people as possible.</p>
                  <p>
                    If you’ve tried to live a healthier lifestyle and struggled to get the results you wanted. Or, if
                    you’re already excited about the amazing benefits of a plant-based diet…But aren’t quite sure how to
                    make it all work…
                  </p>
                  <p>Then join us for the free Food For Health Masterclass.</p>
                  <p>
                    In this class, we’ll share 10 plant-powered breakthroughs to make it easy to thrive with a
                    plant-based diet.
                  </p>
                  <p>Reserve your spot today.</p>
                  <h5 className="m-0">To health,</h5>
                  <img
                    className="my-2"
                    src="https://cdn.foodrevolution.org/global/team/john-signature.png"
                    alt="John signature"
                    style={{maxHeight: '60px', width: 'auto'}}
                  />
                  <h3 className="m-0 text-uppercase">John Robbins</h3>
                </div>
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL id="pptv1-instructor" color="black" className="text-center text-white" type="triangle" angle="both">
          <Container>
            <Row>
              <Col>
                <h3 className="section-only-heading">Meet Your Instructor</h3>
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL
          id="pptv1-john"
          color="white"
          imgUrl="https://cdn.foodrevolution.org/ppt/images/john-03-square.jpg"
          imgPosition="right"
          type="triangle"
        >
          <h2 className="section-heading text-purple mb-2">John Robbins</h2>
          <h4 className="mt-0 text-400">
            One of the world’s most beloved experts on living a plant-powered lifestyle.
          </h4>
          <p>
            John Robbins was groomed to be the heir to the Baskin-Robbins ice cream empire, which was founded and owned
            by his father. He had money, prestige, and security, along with an ice cream cone-shaped swimming pool in
            the backyard.
          </p>
          <p>
            So why did John walk away from a path that was practically “paved with gold and ice cream”? Because his
            conscience was emerging. He didn’t want to devote his life to selling ice cream after realizing it makes
            people unhealthy. So, he decided to make a change and forge a new path.
          </p>
          <p>
            Over the last 35 years, John’s books about healthy eating and healthy living (including the blockbuster
            bestseller, <i>Diet for a New America</i>) have sold millions of copies and been translated into more than
            30 languages. He has made hundreds of local and national radio and television appearances, spoken in person
            to hundreds of thousands of people, founded the nonprofit, EarthSave International, and received dozens of
            awards for his work on behalf of healthy people and a healthy planet.
          </p>
          <p>With his son and colleague, Ocean Robbins, John serves as co-host of Food Revolution Network.</p>
        </SectionCL>
        <SectionCL id="pptv1-host" color="black" className="text-center text-white" type="triangle">
          <Container>
            <Row>
              <Col>
                <h3 className="section-only-heading">Meet Your Host</h3>
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL
          id="pptv1-ocean"
          color="white"
          imgUrl="https://cdn.foodrevolution.org/global/team/frn-guest_expert-portrait-square-ocean_robbins.jpg"
          imgPosition="right"
          type="triangle"
        >
          <h2 className="section-heading text-purple mb-2">Ocean Robbins</h2>
          <h4 className="mt-0 text-400">A food revolution leader and inspiration to millions.</h4>
          <p>
            Ocean Robbins, co-founder and CEO of the million-member Food Revolution Network, is a TEDx speaker,
            best-selling author, husband, father, gardener, and dancer. Born in a log cabin and raised on home-grown
            food, his work is rooted in his family’s health-conscious legacy. From founding the nonprofit YES! at age 16
            to leading a global food revolution movement, Ocean is on a mission to transform our food system, drive down
            the chronic disease epidemic, and mobilize people worldwide to be part of the solution for a healthier
            planet and population. His books include <i>31-Day Food Revolution</i> and <i>Powered By Plants.</i>
          </p>
        </SectionCL>
        <SectionCL
          id="pptv1-cta"
          color="purple"
          className="text-center text-white"
          type="triangle"
          angle="none"
          order="last"
        >
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading mb-2">
                  <i>Don’t Miss Your Chance!</i>
                </h2>
                <h3 className="m-0">For a limited time watch the</h3>
                <Logo src="https://cdn.foodrevolution.org/ppt/ppt-masterclass-logo-white.svg" className="my-5" />
                <div>{ctaButton}</div>
                {variant != 'ad' && <LandingTimer className="mt-4" style="light" />}
              </Col>
            </Row>
          </Container>
        </SectionCL>
      </div>
      <div className="footer-content my-auto">
        <Footer adVariant={variant === 'ad' ? true : false} />
      </div>

      <OptinModal entry={fromEntry} />
      {exitModalEnabled && (
        <ExitModal
          exitDelay={30000}
          content={
            <>
              <h4 className="section-heading mb-4">
                Don’t miss out on this <br className="d-none d-xl-block" />
                life-changing information!
              </h4>
              <p className="mb-0">
                Discover why food is the foundation <br className="d-none d-xl-block" />
                of your health and 10 mighty <br className="d-none d-xl-block" />
                plant-powered breakthroughs.
              </p>
            </>
          }
          image={{
            url: 'https://cdn.foodrevolution.org/ppt/images/1001955768-exit.jpg',
            alt: 'girl holding slices of carrots over her eyes',
            width: 1000,
            height: 800
          }}
          positiveOptin
          positiveButtonText="Learn More Now"
          positiveButtonColor="exit-orange"
          negativeButtonText="No Thanks"
        />
      )}
    </div>
  );
}
